.signinTitle {
  font-size: 30px;
  /* border: solid; */
  margin: 0 auto;
  text-align: center;
}

.signinCardBody {
  padding: 20px;
}

.spinner {
  position: absolute;
  top: 30%;
  right: 15%;
}

.errorMessage {
  color: var(--red);
}

@import url(https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap);
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.BreadcrumbBar_breadcrumbBar__3F_cC ol {
  margin-bottom: 0;
  background-color: #d8d8d8;
  box-shadow: 4px 1px 18px 0px black;
  z-index: 100;
  position: relative;
}

.SignIn_signinTitle__24aLR {
  font-size: 30px;
  /* border: solid; */
  margin: 0 auto;
  text-align: center;
}

.SignIn_signinCardBody__3i77z {
  padding: 20px;
}

.SignIn_spinner__15x_I {
  position: absolute;
  top: 30%;
  right: 15%;
}

.SignIn_errorMessage__lQPKM {
  color: var(--red);
}

.Route_routePageCol__3OXqP {
  padding: 0 !important;
  height: 100%;
}

body {
  margin: 0;
  font-family: "M PLUS 1p", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #000000;
}
.btn-red {
  background-color: #a10202;
  transition: opacity 0.8s;
}
.btn-grey {
  background-color: #666666;
}

.btn-red,
.btn-grey {
  transition: opacity 0.8s;
  color: white;
}

.titlecustom {
  font-family: "Barlow Condensed", sans-serif;
}
.btn-red:hover,
.btn-grey:hover {
  opacity: 0.8;
  color: white;
}

.btn-red[disabled],
.btn-grey[disabled] {
  pointer-events: none;
}

.drop-down {
  height: 250px;
  overflow-y: scroll;
}


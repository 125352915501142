@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap");

body {
  margin: 0;
  font-family: "M PLUS 1p", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #000000;
}
.btn-red {
  background-color: #a10202;
  transition: opacity 0.8s;
}
.btn-grey {
  background-color: #666666;
}

.btn-red,
.btn-grey {
  transition: opacity 0.8s;
  color: white;
}

.titlecustom {
  font-family: "Barlow Condensed", sans-serif;
}
.btn-red:hover,
.btn-grey:hover {
  opacity: 0.8;
  color: white;
}

.btn-red[disabled],
.btn-grey[disabled] {
  pointer-events: none;
}

.drop-down {
  height: 250px;
  overflow-y: scroll;
}
